
import { GET_PERSONAL_SUBDOMAIN } from "../ActionType/Types";
import Constants from "../../Constant"

// enrollementId/:courseId
export function GetPersonalSubDomainAction(token,employeeId,onSuccess ,onFailure){
    console.log("employeeId",employeeId)
    return{
        type:GET_PERSONAL_SUBDOMAIN,
        payload:{
            apiUrl:`${Constants.getPersonalSubDomain}/${employeeId}`,
            header:"application/json",
            metaData:true,
            requestType:'GET',
            reduxActionType:null,
            onSuccess:onSuccess,
            onFailure:onFailure,
            token:token,

        }
    }
}
