import { DELETEBOOKS,ADMIN_DELETE_BOOKS } from "../ActionType/Types";
import Constants from "../../Constant"
export function DeletebookAction(token,BookId,onSuccess ,onFailure){
    return{
        type:ADMIN_DELETE_BOOKS,
        payload:{
            apiUrl: `${Constants.deletebook}/${BookId}`,
            metaData:true,
            header:"application/json",
            requestType:'DELETE',
            reduxActionType:DELETEBOOKS,
            onSuccess:onSuccess,
            onFailure:onFailure,
            body:{
                token:token,
            }
        }
    }
}