import React, { useEffect } from "react";
import Header from "../custom/topnavBar";
import { useLocation, useParams } from "react-router-dom";
import {  useDispatch,useSelector } from "react-redux";
import { routesName } from "../routes/routesName";
import { getRoleData, getAccessToken, getStudentData } from "../loginData/loginData";
// import { GetclasscomponentAction } from "../redux/Actions/GetActions/getclasscomponentAction";
import { getTeacherData,gettaData} from "../loginData/loginData";
import { sideBarActiveTabAction } from "../redux/Actions/GetActions/getSideBarDataAction";
import ClassroomTabs from "./Tabs";

const ClassroomLandingPage = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { courseName } = useParams();
    const { loginData } = useSelector((state) => state?.LOGINREDUCER);

    const roleData = getRoleData(loginData);
    const { RoleId, RoleName, UserroleId } = roleData || {};
    const navigationRoute=RoleName=="Student"?routesName.StudentDashboard:routesName.dashboardView
    const rows = location.state;
    const classItem = rows;
    const studentData = getStudentData(loginData);
    const Teacherdata=getTeacherData(loginData)
    const TaData=gettaData(loginData)
    useEffect(() => {
        if (courseName &&RoleName!="Student") {
            dispatch(sideBarActiveTabAction("CourseHome"));
        }
    }, [courseName]);
    const getDataSource = () => {
        if (TaData && TaData.length > 0) {
          return TaData;
        } else if (studentData && studentData.length > 0) {
          return studentData;
        } else if (Teacherdata && Teacherdata.length > 0) {
          return Teacherdata;
        }
        return [studentData,TaData,Teacherdata];
      };
      const CourseId=getDataSource()?.find((enrollment) => enrollment.CourseName === courseName)
    return (
        <>
            <div style={{ overflow: "hidden" }}>
                <Header title={courseName} hideLogout={false} showAddIcon={false} navigations={navigationRoute} sPath={routesName.setting} showpendingIcon={false} courseId={CourseId.CourseId}/>
                <ClassroomTabs classItem={classItem}  courseName={courseName} />
            </div>
        </>
    );
};

export default ClassroomLandingPage;
