import { ADMIN_POST_ATTACHMENTS } from "../../Actions/ActionType/Types";


import {takeEvery} from "redux-saga/effects";
import fetchData from "../sagaHelper";

export  function * PostattachmentSaga(){

    yield takeEvery(ADMIN_POST_ATTACHMENTS,fetchData);
}
