import { SIDEBAR_DATA } from "../../Actions/ActionType/Types"; 
const initialState = {
    sideBarData: [],
    ActiveTab:""
};
export default function sideBarDataReducer(state = initialState, action) {
    switch (action.type) {
        case SIDEBAR_DATA:
            return {
                ...state,
                sideBarData: action?.payload,
            };
        case "ACTIVE_TAB":
            return {
                ...state,
                ActiveTab: action?.payload,
            };
        default:
            return state;
    }
}