
export const Btnsx = {
    // textTransform: "capitalize",
    // borderRadius: "8px",
    // fontWeight: "500",
    // fontSize: "13px",
    // padding: "12px 20px",
    // color: "#fff !important",
    textTransform: 'capitalize',
    borderRadius: '30px',
    mt: '10px',
    p: '10px 30px',
    fontSize: '14px',
    color: "#fff !important",
  };
  

  export const commonTypo={
    fontSize: 18,
    fontWeight: 500,
    paddingBottom: "2px",
    mb: "15px",
  }