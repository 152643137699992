import { LOGIN ,ADMIN_LOGIN } from "./ActionType/Types";
// import constants from "../../Constants"
import Constants from "../Constant"
// password
export function LoginAction(email ,getuserRoll ,onSuccess , onFailure){
    return{
        type:ADMIN_LOGIN,
        payload:{
            apiUrl:Constants.login_api,
            header:'application/json',
            metaData:true,
            requestType:'POST',
            body:{
                email:email,
                role:getuserRoll,
            },
            reduxActionType:LOGIN,
            onSuccess:onSuccess,
            onFailure:onFailure,

        }
    }
}