export const helperText = {
    otp:"Enter OTP",
    profile: "Profile",
    signin: "Sign In",
    signup: "Sign Up",
    forgotPassword: "Forgot your password?",
    pageNotFound: "404 Not Found",
    or: "or",
    email: "Email",
    password: "Password",
    getStarted: "Get's started.",
    sendResetLink: "Send Reset Link",
    lockScreen: "Lock Screen",
    edit: "Edit",
    update: "Update",
    loginBack :"Back to Login",
    noAccount:"  Don't have an account?",
    termService:" Terms of Service",
    privacyPolicy:" Privacy Policy",
    byloginText:"  By Logging up, you agree to the",
    loginpageHeading:"   Admin Login Page",
    login:"Login",
    enterYourEmailAnd: "Enter your email and we′ll send you instructions to reset your password",
    bysignUp:" By Signing up, you agree to the",
    signuppageHeading:" Admin Signup Page",
    error404:" Oops!! The page you are looking for doesn't exist!",
    error300:"  The page you are looking for might have been removed had its name changed or is temporarily unavailable.",
    backhomePage:"  Back To Home",
    departmentTitle :"Department",
    addDepermentText:"Add Department",
    welcome:"Welcome to the classroom",
    aim:"Our mission is to create an optimal learning environment, ensuring the best conditions for academic excellence. ",
    emptyfeilderror:"All fields should have values.",
    isstring :" Name should only contain letters.",
    isInt:" should be a positive integer.",
    nonegValue:"No negative values.",
    statusInactive :"Status Already Inactive!. Cannot Perfrom this action",
    createclasscomponent:"Class Component",

    lecRoom :"Lecture Room",
    controlPanel:"Control Panel",
    comments:"Comments",

    ProjectTitle :"SCHOLARSPACE",
    loginText:"Login to access your account",
    projectPurpose:"ScholarScape is a Learning Management System (LMS) designed to automate and streamline the  educational experience for both educators and learners",

    youwillassignAssignment:"This is where you’ll assign work",
    assignmentDetails:"Here you can create assignments for the class",

    loginvalidationPassword:"Password should have at least one uppercase letter and one special character.",
    loginvalidationEmail:"Invalid email format.",
    loginvalidationCnic:"Invalid Cnic Number .This is correct formate XXXXX-XXXXXXX-X",
    loginValidPhone:"Invalid Phone Number Lenght should be 11 & start with Zero XXXXXXXXXXX",

    securityText:"Security",
    updatepasswordHere:"  Update your password here.",
    oldPassword:" Old Password *",
    newPassword:" New Password *",
    confirmPassword:"Confirm Password *",
    emailAddress:"Email Address *",
    pwbtnText:"Change Password",
    changepwHeader:"CHANGE PASSWORD",

    coursesenrolledasStudent:"Enrolled as  Student ",
    enrollementsOffered:"Enrollements Offered",
    personalDetails:"Personal Details",
    enrolledasTa:"Enrolled as Teacher Assistant",
    teachingcourse:"Current Semester Taught",

    nosubmissionFound:" No submission found",
    studentsubmission:"Student Submissions",
    select_file_from_here:"Select file from this PC",
    place_link:"Please paste the link here",

    invalidcredentials:"Invalid Credentials.Please check again",

    classroomtitle:'Scholarspace Classroom',

    publicPage:"Public Courses of ITU",
    forgetpasswordText:" Enter your email and we′ll send you instructions to reset your password",
    sendLink:"Send Reset Link",
    backtoLogin:"Back to Login ",

    otp:"OTP",
    optmessage:"Check your e-mail for 6-character OTP code",
    enter:"Enter",
    backtolink:"Back to Login",
    rolldata:"Please Select the Role.It should not be 'Empty'",
}