const constants = {
    api_base_url: 'https://scholarspace.online', //Live
    // api_base_url: 'http://localhost:3000',  //Local
    // api_base_url: 'https://scholarspace.online/staging',  //Staging

    login_step_2_api: '/api/loginStep2',
    react_base_url:'https://scholarspace.online',
    react_domain: "scholarspace.online",

    login_api: '/api/login',
    signup:'/api/signup',
    logout:'/api/logout',

    // classcomponent
    getclasscomponent:"/api/classcomponents",
    addclasscomponent:"/api/classcomponents",
    deleteclasscomponent:"/api/classcomponents",
    update_classcomponent:"/api/classcomponents",
    update_question_marks_ans:"/api/questionevaluations",

    // subcomponent
    getsubcomponent:"/api/subcomponents",
    addsubcomponent:"/api/subcomponents",
    updatesubcomponent:'/api/subcomponents',
    deletesubcomponent:'/api/subcomponents',
    addtemplatesubcomponent:'/api/TemplateSubcomponents',
    gettemplatesubcomponent:'/api/TemplateSubcomponents',


    // chats
    addchats:"/api/chats",
    getchats:"/api/chats",

    // student in courses
    getstudentincourse:"/api/studentsbycourse",
    // lecture attendance
    addlectureattendace :'/api/lectureattendance',
    // course calender
    getcoursetimetable:"/api/timetable",

    // course student enrolled
    getstudentcourseenrolled:"/api/enrollementsById",
    //  stundet submisson
    addsubdentsubmission:"/api/studentsubmission",
    getstudentsubmission:"/api/studentSubmission",
    studentSubmissionattachments:"/api/studentSubmission",
    // attachments 
    addattachments:"/api/attachments",

    // subcomponent marks
    addsubcomponentmarks:'/api/subcomponentmarks',
    getsubcomponentmarks:'/api/subcomponentmarks',
    // get sub attach
    getsubattachments:"/api/allStudentSubmissions",
    deletestudentsubmission:"/api/DeleteStudentsubmission",

    // get student marks
    getstudentmarks:"/api/subcomponentmarks",
    updatestudentmarks:"/api/subcomponentmarks",

    // book
    getbook:"/api/books",
    deletebook:"/api/books",
    addbook:"/api/books",
    updatebook:"/api/books",

    // student enrollement
    addstudentenrollement:"/api/enrollements",
    getstudentenrollement:"/api/plannedcourses",

    // prefferedSlot
    addprefferedSlot:'/api/prefferedSlot',

    // Time slot
    timeslot:'/api/timeslots',


    // Attendance
    studentattendance:'/api/studentAttendance',

    // Public page
    public_page:"/api/publicPage",

    // clos
    clos:'/api/clos',

    // student perfromance
    individualstudentPerformance:'/api/getStudentPerformance',

     // pending courses
     pendingcourses:'/api/appliedcoursesbystudentUserId',

    // whole class perfromance
    wholeclassperformance:'/api/getClassPerformance',

    // question chatgpt integration
    questionrephrase:"/api/rephraseQuestion",

    // student by course id
    studentbycourseid:'/api/studentsbycourse',

    // class aggregate
    getclassaggregate:'/api/getclassaggregate',
    
    getpersonalsubdomain:'/api/personalSubdomain',


    // question 
    question :'/api/questions',
    
    get_question_by_subcomponent_id:'/api/questionsbysubcomponent',

    addStudentQuestion :'/api/questionevaluations',
    getclographdata:'/api/getclographdata',

    activity_outcome:"/api/activityOutcome",
    activities:"/api/activities",
    calendarEvents:"/api/calendarEvents",
    maxMinAggregate:"/api/getmaxminAggregate/",
    supportIssue:"/api/supportTask",
    

    subcomponentGradeSheet:"/api/subComponentGradeSheet/"

}
// const login_api =   constants.api_base_url+constants.login_api;
const login_api =constants.api_base_url+constants.login_api;
const logout =constants.api_base_url+constants.logout;
const reactDomain=constants.react_domain
// 2 way auth
const login_step_2_api = constants.api_base_url+constants.login_step_2_api;
// class component
const getclasscomponent = constants.api_base_url+constants.getclasscomponent;
const addclasscomponent =constants.api_base_url+constants.addclasscomponent;
const deleteclasscomponent= constants.api_base_url+constants.deleteclasscomponent;
const update_classcomponent =constants.api_base_url+constants.update_classcomponent;


const update_question_marks_ans =constants.api_base_url+constants.update_question_marks_ans;

// sub component
const getTemplateSubcomponent=constants.api_base_url+constants.gettemplatesubcomponent;
const getsubcomponent = constants.api_base_url+constants.getsubcomponent;
const addsubcomponent =constants.api_base_url+constants.addsubcomponent;
const addTemplateSubcomponent=constants.api_base_url+constants.addtemplatesubcomponent;
const updatesubcomponent =constants.api_base_url+constants.updatesubcomponent;
const deletesubcomponent =constants.api_base_url+constants.deletesubcomponent;

// chats
const addchats = constants.api_base_url+constants.addchats;
const getchats =constants.api_base_url+constants.getchats;
// student in courses
const getstudentincourse =constants.api_base_url+constants.getstudentincourse;
// lecture attendance
const addlectureattendace=constants.api_base_url+constants.addlectureattendace;
// course calender
const getcoursetimetable =constants.api_base_url+constants.getcoursetimetable;
// course student enrolled
const getstudentcourseenrolled =constants.api_base_url+constants.getstudentcourseenrolled;
// student submission
const addsubdentsubmission =constants.api_base_url+constants.addsubdentsubmission;
const getstudentsubmission =constants.api_base_url+constants.getstudentsubmission;
// attachments
const addattachments =constants.api_base_url+constants.addattachments;
// sub component marks
const addsubcomponentmarks = constants.api_base_url+constants.addsubcomponentmarks;
const getsubcomponentmarks =constants.api_base_url+constants.getsubcomponentmarks;
// get ssub attach

const getsubattachments= constants.api_base_url+constants.getsubattachments;

// student submission attachments
const studentSubmissionattachments= constants.api_base_url+constants.studentSubmissionattachments;
// delete student submission
const deletestudentsubmission= constants.api_base_url+constants.deletestudentsubmission;
// get student marks
const getstudentmarks =constants.api_base_url+constants.getstudentmarks;
const updatestudentmarks =constants.api_base_url+constants.updatestudentmarks;

// book
 const getbook= constants.api_base_url+constants.getbook;
 const deletebook = constants.api_base_url+constants.deletebook;
 const addbook= constants.api_base_url+constants.addbook;
 const updatebook =constants.api_base_url+constants.updatebook;

 // student enrollemnts
 const addstudentenrollement =constants.api_base_url+constants.addstudentenrollement;
 const getstudentenrollement =constants.api_base_url+constants.getstudentenrollement;
 const CalendarEvents=constants.api_base_url+constants.calendarEvents



 // Prefered time slot 
 const addprefferedSlot =constants.api_base_url+constants.addprefferedSlot;

 // Time SLot

 const timeslot =constants.api_base_url+constants.timeslot;


 // Attendance 

 const studentattendance =constants.api_base_url+constants.studentattendance;
 

 // base

//  const base_url_for_public_page = constants.react_base_url;
const base_url_for_public_page = constants.react_base_url+"/auth/login";

 // public

 const public_page=constants.api_base_url+constants.public_page;

 // clos
 const clos =  constants.api_base_url+constants.clos;

 // individua student perfromance
 const individualstudentPerformance =constants.api_base_url+constants.individualstudentPerformance;

  // individua student perfromance
  const pendingcourses =constants.api_base_url+constants.pendingcourses;

 // whole class api
 const wholeclassperformance = constants.api_base_url+constants.wholeclassperformance;

 // Question rephrase
 const questionrephrase = constants.api_base_url+constants.questionrephrase;

 // student by course 
 const studentbycourseid =constants.api_base_url+constants.studentbycourseid;

 // class aggregate 
 const getclassaggregate =constants.api_base_url+constants.getclassaggregate;
 const getPersonalSubDomain=constants.api_base_url+constants.getpersonalsubdomain


 // Question 

 const question  = constants.api_base_url+constants.question;


 // question by sub component id
 const get_question_by_subcomponent_id  =constants.api_base_url+constants.get_question_by_subcomponent_id;

 // add student queston
 const addStudentQuestion = constants.api_base_url+constants.addStudentQuestion;

 const getclographdata = constants.api_base_url+constants.getclographdata;
 const activityOutcome =constants.api_base_url+constants.activity_outcome
 const activities =constants.api_base_url+constants.activities
 const maxMinAggregate=constants.api_base_url+constants.maxMinAggregate
 const api_base_url=constants.api_base_url;
 const supportIssue=constants.api_base_url+constants.supportIssue
 const subcomponentGradeSheet=api_base_url+constants.subcomponentGradeSheet

module.exports = {
    api_base_url,
    supportIssue,
    base_url_for_public_page,
    reactDomain,
    public_page,
    login_api,
    logout,
    // Otp Auth
    login_step_2_api,
   
    getclasscomponent,
    addclasscomponent,
    deleteclasscomponent,
    update_classcomponent,
    update_question_marks_ans,
    // subcomponent
    getsubcomponent,
    addsubcomponent,
    updatesubcomponent,
    deletesubcomponent,
    addTemplateSubcomponent,
    getTemplateSubcomponent,
    // chats
    addchats,
    getchats,

    // student in course
    getstudentincourse,
    // lecture attendance
    addlectureattendace,
    // course calender
    getcoursetimetable,
    // course student enrolled
    getstudentcourseenrolled,
    // student submission
    addsubdentsubmission,
    getstudentsubmission,
    // attachments
    addattachments,
    // sub component marks
    addsubcomponentmarks,
    getsubcomponentmarks,
    // get sub attach
    getsubattachments,

    // student sub attach
    studentSubmissionattachments,

    // delete student submission
    deletestudentsubmission,
    //  student marks
    getstudentmarks,
    updatestudentmarks,
    //book
    getbook,
    deletebook,
    addbook,
    updatebook,

    // student enrollements
    addstudentenrollement,
    getstudentenrollement,

    // Pre ferred time slot
    addprefferedSlot,

    // Time slot
    timeslot,


    // Attendance 

    studentattendance,

    // Clos
    clos,

    // individual performance
    individualstudentPerformance,
    // whole class performance
    wholeclassperformance,
    // Question Rephrase
    questionrephrase,

    //pending courses by studentId
    pendingcourses,

    // student by course
    studentbycourseid,


    // class aggregate
    getclassaggregate,

    getPersonalSubDomain,


    // questions
    question,

    get_question_by_subcomponent_id,
    addStudentQuestion,
    getclographdata,


    activityOutcome,
    activities,
    CalendarEvents,
    maxMinAggregate,
    subcomponentGradeSheet,
}

