import { GET_CLO_GRAPH,GETCLOGRAPH } from "../ActionType/Types";
import Constants from "../../Constant"

export function GetCloGraphAction(token ,CourseId,onSuccess ,onFailure){
    return{
        type:GET_CLO_GRAPH,
        payload:{
            metaData: true,
            header:"/application/json",
            // apiUrl: constants.getclasscomponent,
            apiUrl:`${Constants.getclographdata}/${CourseId}`,
            requestType:'GET',
            reduxActionType: GETCLOGRAPH  ,
            onSuccess:onSuccess,
            onFailure:onFailure,
            token:token,
        }
    }
}