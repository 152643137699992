import { ADMINGETTIMESLOT ,LOGOUT } from "../../Actions/ActionType/Types";

const initialState={
    timeslotData:[]
}

export default function GettimeslotReducer(state=initialState,action){
    switch (action.type){
        case ADMINGETTIMESLOT:
            return{
                ...state,
                timeslotData: action.payload,
            };
            case LOGOUT:return initialState;
        default:return state;
    }
}