import { GETSTUDENTENROLLEMENTFORCOURSE ,ADMIN_GET_STUDEN_ENROLLEMENT_FOR_COURSE } from "../ActionType/Types";
import Constants from "../../Constant"

export function GetstudentenrollmentforcourseAction(token,StudentUserId,onSuccess ,onFailure){
    console.log("Get" ,`${Constants.getstudentenrollement}/${StudentUserId}`); 
    return{
        type:ADMIN_GET_STUDEN_ENROLLEMENT_FOR_COURSE,
        payload:{
            apiUrl:`${Constants.getstudentenrollement}/${StudentUserId}`,
            header:"application/json",
            metaData:true,
            requestType:'GET',
            reduxActionType:GETSTUDENTENROLLEMENTFORCOURSE,
            onSuccess:onSuccess,
            onFailure:onFailure,
            token:token,

        }
    }
}