// import React, { useEffect } from 'react';
// import ScrollToTop from '../custom/scrolltoTop';
// import ControlPanelModal from '../components/themeIndex';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { routesName } from '../routes/routesName';
// import Footer from '../custom/Footer';
// import { useSelector } from 'react-redux';

// const Layout = ({ children }) => {
//   const location = useLocation();
//   const navigate = useNavigate();

//   useEffect(() => {
//     console.log('Current Location:', location.pathname);
//   }, [location]);

//   const isLoginPage = location.pathname === routesName.loginPage;
//   const isHomePage = location.pathname === routesName.dashboardView;
//   const { loginData } = useSelector((state) => state?.LOGINREDUCER);

//   useEffect(() => {
//     if (!loginData?.payload) {
//       navigate(routesName.loginPage);
//     }
//   }, [loginData, navigate]);

//   return (
//     <>
//       <div className={"active"}>
//         <>
//           {/* <Userdetails/> */}
//         </>
//         <div className="main-content">
//           {children}
//           {isHomePage && <Footer />}
//         </div>
//       </div>
//       {!isLoginPage && (
//         <>
//           <ScrollToTop />
//           <ControlPanelModal />
//         </>
//       )}
//     </>
//   );
// };

// export default Layout;

import React, { useEffect } from 'react';
import ScrollToTop from '../custom/scrolltoTop';
// import ControlPanelModal from '../components/themeIndex';  
import { useLocation, useNavigate } from 'react-router-dom';
import { routesName } from '../routes/routesName';
import Footer from '../custom/Footer';
import { useSelector } from 'react-redux';

const Layout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { loginData } = useSelector((state) => state?.LOGINREDUCER);

  // useEffect(() => {
  //   console.log('Current Location:', location.pathname);
  //   // Check if loginData is empty and redirect to login page if necessary
  //   if (!loginData?.payload) {
  //     navigate(routesName.loginPage);
  //   }
  // }, [location, loginData, navigate]);

  useEffect(() => {
    // Handle browser back button press
    const handlePopState = () => {
      if (!loginData?.payload) {
        navigate(routesName.loginPage);
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [loginData, navigate]);

  const isLoginPage = location.pathname === routesName.loginPage;
  const isHomePage = location.pathname === routesName.dashboardView;

  return (
    <>
      <div className={"active"}>
        <>
          {/* <Userdetails/> */}
        </>
        <div className="main-content">
          {children}
          {/* {isHomePage && <Footer />} */}
        </div>
      </div>
      {!isLoginPage && (
        <>
          <ScrollToTop />
          {/* <ControlPanelModal /> */}
        </>
      )}
    </>
  );
};

export default Layout;
