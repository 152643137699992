// import {LOGOUT} from "./ActionType/Types";
// export function LogoutAction() {
//     return {
//         type: LOGOUT,
//     };
// }
// import Constants from "../../Constant"
import Constant from "../Constant";
import {LOGOUT ,ADMIN_LOGOUT} from "./ActionType/Types";
export function LogoutAction(token,email,onSuccess,onFailure  ){
    console.log("called logout api")
    return{
        type:ADMIN_LOGOUT,
        payload:{
            // apiUrl:Constant.logout_api,
            apiUrl:`${Constant.logout}`,
            header:"application/json",
            metaData:true,
            requestType:'POST',
            reduxActionType:LOGOUT,
            onSuccess:onSuccess,
            onFailure:onFailure,
            body:{
             email:email 
            },
            token:token,
        }
    }
}