import { POSTCHAT } from "../../Actions/ActionType/Types";

const initialState={
    addchatData:[]
}
export default function PostchatReducer(state =initialState,action){
    switch (action.type){
        case POSTCHAT:
            return{
                ...state,
                addchatData: action.payload,
            };
        default:return state;
    }
}