
import { GETPENDINGCOURSES ,GET_PENDING_COURSES } from "../ActionType/Types";
import Constants from "../../Constant"

// enrollementId/:courseId
export function GetPendingCoursesAction(token,enrollementId,onSuccess ,onFailure){
    console.log("enrollementId",enrollementId)
    return{
        type:GET_PENDING_COURSES,
        payload:{
            apiUrl:`${Constants.pendingcourses}/${enrollementId}`,
            header:"application/json",
            metaData:true,
            requestType:'GET',
            reduxActionType:GETPENDINGCOURSES,
            onSuccess:onSuccess,
            onFailure:onFailure,
            token:token,

        }
    }
}
