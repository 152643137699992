
import {ADMIN_LOGIN_STEP_2 ,LOGIN} from "../ActionType/Types";
// import constants from "../../Constants"
import Constants from "../../Constant";



export function LoginStepTwoAction(email,otp,userRole,currentUrl,onSuccess , onFailure){
    console.log("Inside Actionnnn")
    return{
        type:ADMIN_LOGIN_STEP_2,
        payload:{
            apiUrl:Constants.login_step_2_api,
            header:'application/json',
            metaData:true,
            requestType:'POST',
            body:{
                email:email,
                OTP:otp,
                userRole: userRole,
                currentUrl:currentUrl
            },
            reduxActionType:LOGIN,
            onSuccess:onSuccess,
            onFailure:onFailure,

        }
    }
}