import { GETSTUDENTENRTOLLEDINCOURSE,LOGOUT } from "../../Actions/ActionType/Types";
const initialState={
    getstudentincourseData:[]
}
export default function GetstudentenrolledincourseReducer(state =initialState,action){
    switch (action.type){
        case GETSTUDENTENRTOLLEDINCOURSE:
            return{
                ...state,
                getstudentincourseData: action.payload,
            };
            case LOGOUT:return initialState;
        default:return state;
    }
}