import { GETSTUDENTENRTOLLEDINCOURSE , ADMIN_GET_STUDENT_ENROLLED_IN_COURSE } from "../ActionType/Types";
import Constants from "../../Constant"

export function GetstudentenrolledincourseAction(token,CourseId ,  onSuccess ,onFailure){
    return{
        type:ADMIN_GET_STUDENT_ENROLLED_IN_COURSE,
        payload:{
            apiUrl:`${Constants.getstudentincourse}/${CourseId}`,
            header:"application/json",
            metaData:true,
            requestType:'GET',
            reduxActionType:GETSTUDENTENRTOLLEDINCOURSE,
            onSuccess:onSuccess,
            onFailure:onFailure,
            token:token,

        }
    }
}