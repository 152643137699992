import { GETCOURSESTUDENTENROLLED,LOGOUT } from "../../Actions/ActionType/Types";


const initialState={
    getcoursestudentenrolledData:[]
}
export default function GetcoursestudentenrolledReducer(state =initialState,action){
    switch (action.type){
        case GETCOURSESTUDENTENROLLED:
            return{
                ...state,
                getcoursestudentenrolledData: action.payload,
            };
            case LOGOUT:return initialState;
        default:return state;
    }
}