import React ,{useState}from "react";
import { routesName } from "../routes/routesName";
import {LoginStepTwoAction} from "../redux/Actions/PostActions/LoginStepTwoAction";
import { Link } from "react-router-dom";
import {Box ,CircularProgress,Grid ,Typography} from "@mui/material";
import {helperText} from "../HelperText/Text";
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GetclosAction } from "../redux/Actions/GetActions/getclosAction";
import loginImg from "../assets/images/flc_design20240310222273.png"
import { react_base_url } from "../redux/Constant";

import "./auth.css"
 const OTPPage = () => {
  const location =useLocation();
  const currentUrl = window.location.href;
    const { payload: loginData } = useSelector((state) => state?.LOGINREDUCER?.loginData);
    console.log("loginData",loginData);
    const dispatch=useDispatch();
    const navigate = useNavigate();
    const [otp, setOtp] = useState(""); 
    const [isLoggingIn, setIsLoggingIn] = useState(false);

    const { email, userRole } = location.state || {};
  console.log(" email, userRole" , email, userRole)
    const handleChange = (event) => {
      setOtp(event.target.value);
    };
     const token = loginData?.payload?.accessToken || '';

  const handleSubmit = (event) => {
    setIsLoggingIn(true);

      event.preventDefault();
      if(otp!=="")
      {
        console.log("LoginStepTwoAction",email, userRole)
  
        dispatch(LoginStepTwoAction(loginData,otp,userRole,currentUrl,
      (response) => {
        console.log("response",response)
        if(response.status===200)
        {

          if(response?.payload?.Navigation?.length>0)
          {
            navigate(`${routesName.classroomHome}/${response?.payload?.Navigation[0]?.CourseName}/${response?.payload?.Navigation[0]?.CourseId}`,)
            return 
          }
          if(response.payload.studentData.length>0)
          {
            navigate(routesName.StudentDashboard)
          }
          else{
            navigate(routesName.dashboardView);
          }
        
        dispatch(GetclosAction(token))
        }
          },
          (error) => {
            console.log("error",error);
            setIsLoggingIn(false);  
          }
          ));
  
        }
      else{
        console.log("Otp field is empty")
      }
      }
    return (
      <>
       <div className="login-container">
        <Box  className="bg-white px-6 py-14 shadow-md rounded-[4px] box-shadow-animation "  style={{width:"500px"}}>
        <div className="flex justify-center ">
            <img  src={loginImg} width={'130px'} className="image-animation"/>

            </div>
        <div className="authenticationBox  " >
          <Box component="main" >
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Box>
                <Typography
                  as="h1"
                  fontSize="28px"
                  fontWeight="700"
                  mb="5px"
                >
                  {helperText.otp}{" "}
                </Typography>
  
                <Typography fontSize="15px" mb="30px">
                  {helperText.optmessage}
                </Typography>
  
                <Box component="form" noValidate onSubmit={handleSubmit}>  
                <Box sx={{padding:"30px 20px" , borderRadius:"10px" ,mb:'20px ' ,minWidth:"340px"}} className="bg-gray-700 text-gray-50">
                  
                    <Grid container alignItems="center" spacing={2}>
                      
                      <Grid item xs={12}>
                        <Typography
                          component="label"
                          sx={{
                            fontWeight: "500",
                            fontSize: "14px",
                            mb: "10px",
                            display: "block",
                          }}
                        >
                          {helperText.otp}
                        </Typography>
                        <input
                      
                            id="otp"
                            className="mb-5 rounded-[4px] border p-3 hover:outline-none focus:outline-none hover:border-yellow-500 "
                            type="email"
                            placeholder="OTP Code"
                            value={otp}
                            onChange={handleChange}
                            style={{ width: "100%",color:'black' }}
                          />
                       
                      </Grid>
                    </Grid>
                  </Box>
                  <div className="flex justify-center pt-4">

                  {isLoggingIn ? (
                  <CircularProgress size={24} />
                    ) : (
                   <button   
                   type="submit"
                   className="mt-5 w-full border p-2 bg-gradient-to-r from-gray-800 bg-gray-500 text-white rounded-[4px] hover:bg-slate-400 scale-105 duration-300" > {helperText.enter}</button>
                  )}
                </div>
                   </Box>
  
                <Box as="div" textAlign="center" mt="20px">
                  <Link
                    to={routesName.loginPage}
                    className="primaryColor text-decoration-none underline  text-black"
                    style={{
                      fontSize:"16px"
                    }}
                  >
                    <i className="ri-arrow-left-s-line"></i>{" "}
                    {helperText.backtolink}
                  </Link>
                </Box>
                <div className="mt-5 flex justify-center text-sm text-gray-400">
              <p>
                This site is protected by reCAPTCHA and the Google <br />
                <a className="underline" href="">
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a className="underline" href="">
                  Terms of Service
                </a>{" "}
                apply.
              </p>
            </div>
              </Box>
            </Grid>
          </Box>
        </div>
        </Box>
        </div>
  
        {/* </div> */}
      </>
    );
  };
  
export default OTPPage;
