import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  List,
  ListItem,
  ListItemText,
  Card,
  CircularProgress,
  Avatar,
} from "@mui/material";
import { Add, Close, Delete, DeleteOutline, Update } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import errorImage from "../assets/images/empty_states_home_greyscale.svg";
import { helperText } from "../HelperText/Text";
import AddclassComponent from "./modals/addclasscomponentModal";
import { green, red } from "@mui/material/colors";
import { DeleteclasscomponentAction } from "../redux/Actions/DeleteActions/deleteclasscomponentAction";
import { UpdateclasscomponentAction } from "../redux/Actions/UpdateActions/updateclasscomponentAction";
import { getStudentData, getAccessToken } from "../loginData/loginData";
import { isLoadingAction } from "../redux/Actions/PostActions/isLoadingAction";
import DeleteModal from "./modals/deleteModal";
import UpdateModal from "./modals/UpdateModal";
import { useParams } from "react-router-dom";
import { GetclasscomponentAction } from "../redux/Actions/GetActions/getclasscomponentAction";
import { GetStudentByCourseAction } from "../redux/Actions/GetActions/getstudentbycourseAction";
import back_img from "../assets/images/cameo_banner-1x.png";
import { toast, ToastContainer } from "react-toastify";
import CloGraph from "./CloGraph/CloGraph";
import ClassComponentCreationGuidelines from "./GuidelinesClassComponentCreation";

const datagrid = {
  margin: "30px",
  "& .MuiDataGrid-root": {
    border: "none",
  },
  "& .MuiDataGrid-cell": {
    borderBottom: "none",
    fontSize: "14px",
    color: 'black'
  },
  "& .name-column--cell": {},
  "& .MuiDataGrid-columnHeaders": {
    background: "#e5e7eb",
    borderBottom: "none",
    lineHeight: "20px",
    fontWeight: 400,
    fontSize: "15px",
    color: "#000",
  },
  "& .MuiDataGrid-virtualScroller": {},
  "& .MuiDataGrid-footerContainer": {
    borderTop: "none",
  },
  "& .MuiCheckbox-root": {
    //  border: "none",
  },
  "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
    color: "black",
  },
}
function DefaultPage({ classItem, roleName, isPublic, loading, id }) {
  const publicStatus = isPublic ?? false;
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state?.LOGINREDUCER);
  const token = getAccessToken(loginData);
  const [isLoading, setIsLoading] = useState(true);
  const [openAdd, setopenAdd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const studenData = getStudentData(loginData);
  const { courseName } = useParams();
  const mapArray = studenData;
  const [getstudentcoursedata, setgetstudentcoursedata] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      // dispatch(isLoadingAction(true));
      dispatch(GetStudentByCourseAction(
        token,
        id,
        (response) => {
          setgetstudentcoursedata(response);
          dispatch(isLoadingAction(false));
        },
        (error) => {
          dispatch(isLoadingAction(false));
          console.log("error attc", error);
        }
      ));
    };

    fetchData();
  }, []);
  const matchingEnrollment =
    mapArray && mapArray.find((enrollment) => enrollment.CourseId == id);
  const enrollmentId = matchingEnrollment
    ? matchingEnrollment.EnrollementId
    : null;


  const handleOpenAdd = () => setopenAdd(true);
  const handleCloseAdd = () => setopenAdd(false);
  const handleOpenDelete = (rowData) => {
    setSelectedRowData(rowData);
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setSelectedRowData(null);
    setOpenDelete(false);
  };
  const [getclasscomponentData, setClassComponentData] = useState();
  useEffect(() => {
    // dispatch(isLoadingAction(true));
    dispatch(
      GetclasscomponentAction(
        token,
        id,
        publicStatus,
        (response) => {
          setClassComponentData(response?.payload || []);
          dispatch(isLoadingAction(false));
        },
        (error) => {
          dispatch(isLoadingAction(false));
          console.log("error attc", error);
        }
      )
    );
  }, [updateModalOpen, openAdd, openDelete]);
  const componentData = getclasscomponentData || [];

  const handleDeleteClassComp = (id) => {
    // dispatch(isLoadingAction(true));
    dispatch(DeleteclasscomponentAction(token, id,
      (res) => {
        dispatch(isLoadingAction(false));
        toast.success(res?.message);
      },
      (error) => {
        dispatch(isLoadingAction(false));
        console.log(error?.message)
        if (error?.message.includes("Already exist")) {
          toast.error(error.message);
        }
        else {

          const payloadValues = error.status != 500 ? Object.values(error.payload) : " Server Error " + error?.status;
          const extractedError = (payloadValues?.length > 0 && error?.status != 500) ? payloadValues[0] : payloadValues;
          // console.log("MyError",extractedError);
          toast.error(extractedError);
        }
      }
    ));
  };

  const [ComponentType, setComponentType] = useState("");
  const [ComponentName, setComponentName] = useState("");
  const [Weightage, setWeightage] = useState("");
  const [ComponentPolicy, setComponentPolicy] = useState("");
  const [Status, setStatus] = useState("");

  const handleUpdateClick = (rowData) => {
    setSelectedRowData(rowData);
    setComponentType(rowData.ComponentType);
    setComponentName(rowData.ComponentName);
    setWeightage(rowData.Weightage);
    setComponentPolicy(rowData.ComponentPolicy);
    setStatus(rowData.Status);
    setUpdateModalOpen(true);
  };

  const handleUpdate = (formData) => {
    // dispatch(isLoadingAction(true));
    const { ComponentType, ComponentPolicy, ComponentName, Weightage, Status } =
      formData;
    dispatch(
      UpdateclasscomponentAction(
        token,
        selectedRowData.ComponentID,
        id,
        ComponentType,
        ComponentName,
        Weightage,
        ComponentPolicy,
        Status,
        (response) => {
          
          toast.success(response.message);
          setUpdateModalOpen(false);
          dispatch(isLoadingAction(false));

        },
        (error) => {
          dispatch(isLoadingAction(false));
          console.log("Not Assignment Created", error)

          if (error?.message?.status != 500) {
            if (error?.message?.payload != "") {
              const payloadKeys = Object.keys(error?.message?.payload);
              if (payloadKeys.length > 0) {
                console.log("myError", error?.message);
                toast.error(error?.message?.payload[payloadKeys[0]]);
              }
            } else {
              toast.error(error?.message?.message);
            }
          } else {
            toast.error("Server Error " + error?.message?.status);
          }
        }
      )
    );
  };

  const handleCloseUpdateModal = () => setUpdateModalOpen(false);

  const columns = [
    {
      field: "ComponentName",
      headerName: "Component Name",
      flex: 1,
      minWidth: 180,

    },
    // {
    //   field: "ComponentPolicy",
    //   headerName: "Component Policy",
    //   flex: 1,
    //   minWidth: 180,
    // },
    {
      field: "ComponentType",
      headerName: "Component Type",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "Weightage",
      headerName: "Weightage",
      flex: 1,
      minWidth: 180,
    },
    ...(enrollmentId === null
      ? [
        {
          field: "actions",
          headerName: "Actions",
          flex: 1,
          minWidth: 150,
          renderCell: (params) => (
            <>
              <IconButton onClick={() => handleUpdateClick(params.row)}>
                    <Update  sx={{ color: "orange", fontSize: "18px" }}/>
              </IconButton>
              <IconButton onClick={() => handleOpenDelete(params.row)}>
                <Delete sx={{ color: "red", fontSize: "18px" }} />
              </IconButton>
            
            </>
          ),
        },
      ]
      : []),
  ];

  const [openguidelineModal, setopenguidelineModal] = useState(false);

  const handleOpenGuidelinesModal = () => {
    setopenguidelineModal(true);
  };

  const handleCloseGuidelineseModal = () => {
    setopenguidelineModal(false);
  };
  const firstLetter = courseName ? courseName.charAt(0).toUpperCase() : "";

  const studentcolumns = [

    {
      field: "StudentName",
      headerName: "Student Name",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Box display="flex" alignItems="center">
          <Avatar sx={{ marginRight: 1, height: "26px", width: "26px", background: '#388e3c' }} >
            {params.row.StudentName ? params.row.StudentName.charAt(0).toUpperCase() : ''}
          </Avatar>
          <Typography variant="body1">
            {params.row.StudentName}
          </Typography>
        </Box>
      ),
    },
    {
      field: "RegNum",
      headerName: "Registration Number",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "studentEmail",
      headerName: "Email ",
      flex: 1,
      minWidth: 180,

    },

  ];

  const studentrows = getstudentcoursedata?.payload || [];
  return (
    <>
      <div
        className="flex flex-wrap"
      >
        <div className="flex flex-wrap -m-4" style={{ width: "100%" }}>
          <div className="p-4  md:w-full">
            <div className="flex rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col">
              <div className="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="24" height="24" rx="4"  />
                  <text
                    x="13"
                    y="19"
                    textAnchor="middle"
                    fill="currentColor"
                    fontSize="22"
                    fontWeight="bold"
                    fontFamily="Arial, sans-serif"
                  >
                    {firstLetter}
                  </text>
                </svg>
              </div>
              <div className="flex-grow">
                <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                  {courseName}
                </h2>
                <p className="leading-relaxed text-base text-black">
                  Welcome to {courseName}. Here, you can create a class
                  component and its subcomponents. Click the "Guidelines"
                  button to understand how to proceed.
                </p>
                {enrollmentId === null ? (
                  <div>
                    <Button
                      onClick={handleOpenAdd}
                      variant="contained"
                      sx={{
                        fontWeight: "bold",
                        marginTop: "10px",
                        color: "white",

                      }}
                    >
                      ADD CLASS COMPONENT
                    </Button>
                    <Button
                      onClick={handleOpenGuidelinesModal}
                      sx={{
                        background: "#ffb74d",
                        fontWeight: "bold",
                        marginTop: "10px",
                        marginLeft: "5px",
                        color: "white",
                        "&:hover": {
                          background: "#ffb744",
                        },
                      }}
                    >
                      GUIDELINES
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          marginTop: "30px",
          marginBottom: "30px",
          marginLeft: "30px",
        }}
      >
        <h1 className="text-black text-lg title-font font-medium mb-3 underline">
          {" "}
          Grading Criteria Info{" "}
        </h1>
      </div>
      <Box
        sx={datagrid}
      >
        <>
          <DataGrid
            rows={componentData}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            getRowId={(row) => row.ComponentID}
            components={{ Toolbar: GridToolbar }}
            density="compact"
          />
        </>
      </Box>



      <div
        style={{
          marginTop: "30px",
          marginBottom: "30px",
          marginLeft: "30px",
        }}
      >
        <h1 className="text-black text-lg title-font font-medium mb-3 underline">
          Student Enrolled
        </h1>
      </div>

      <Box sx={datagrid}>
        <DataGrid
          rows={studentrows}
          columns={studentcolumns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          getRowId={(row) => row.EnrollementId}
          components={{ Toolbar: GridToolbar }}
          density="compact"
        />
      </Box>

      <Box
        sx={{
          marginTop: "30px",
          marginBottom: "30px",
          marginLeft: "30px",
        }}
      >
        <CloGraph  CourseId={id}/>
      </Box>

      <AddclassComponent
        open={openAdd}
        close={handleCloseAdd}
        classItem={classItem}
      />
      <DeleteModal
        open={openDelete}
        close={handleCloseDelete}
        selectedRowData={selectedRowData}
        handleDelete={handleDeleteClassComp}
      />
      <UpdateModal
        open={updateModalOpen}
        handleClose={handleCloseUpdateModal}
        handleUpdate={handleUpdate}
        title="Update Class Component"
        inputs={[
          { name: "ComponentName", label: "ComponentName", type: "textfield" },
          { name: "ComponentType", label: "ComponentType", type: "autocomplete" },
          { name: "Weightage", label: "Weightage", type: "number" },
          { name: "ComponentPolicy", label: "ComponentPolicy", type: "number" },
        ]}
        initialData={{
          ComponentName,
          ComponentType,
          Weightage,
          ComponentPolicy,
          Status,
        }}
        autocompleteOptions={{ ComponentType: ["Graded", "Not Graded"] }}
      />
      <Modal open={openguidelineModal} onClose={handleCloseGuidelineseModal}>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            left: "50%",
            transform: "translateX(50%)",
            height: "100vh",
            width: "50%",
            maxWidth: "50vw",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            overflowY: "auto",
            "@media (max-width: 960px)": {
              width: "100%",
              maxWidth: "100%",

              left: 0,
              transform: "none",
              borderRadius: 0,
            },
          }}
        >
          <  ClassComponentCreationGuidelines
            handleCloseGuidelineseModal={handleCloseGuidelineseModal}
          />
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Box>

      </Modal>

    </>
  );
}

export default DefaultPage;