import React from "react";
import {Dialog ,Box ,Typography} from "@mui/material"
const AlertModal = ({ isOpen, onClose,handleClick,Text,ConfirmButtonText,RejectButtonText }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <Box p={4} textAlign="center">
        <svg
          className="mx-auto mb-4 text-amber-200	 w-12 h-12	"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>
        <Typography variant="body1" mb={5} sx={{ color: "text.secondary" }}>
          {Text}
        </Typography>
        <button
          onClick={handleClick}
          className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
        >
          {ConfirmButtonText}
        </button>
        <button
          onClick={onClose}
          className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
        >
          {RejectButtonText}
        </button>
      </Box>
    </Dialog>
  );
};

export default AlertModal;
