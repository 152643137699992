import { PostsubcomponentMarks } from "../../Actions/PostActions/postsubcomponentmarksAction";
const initialState={
    addsubcomponentmarkData:[]
}
export default function PostsubcomponentmarksReducer(state =initialState,action){
    switch (action.type){
        case PostsubcomponentMarks:
            return{
                ...state,
                addsubcomponentmarkData: action.payload,
            };
        default:return state;
    }
}