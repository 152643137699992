import { GETCHATS,LOGOUT } from "../../Actions/ActionType/Types";

const initialState={
    getchatData:[]
}
export default function GetchatReducer(state =initialState,action){
    switch (action.type){
        case GETCHATS:
            return{
                ...state,
                getchatData: action.payload,
            };
            case LOGOUT:return initialState;
        default:return state;
    }
}