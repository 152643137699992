// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   Typography,
//   Grid,
//   Avatar,
//   AvatarGroup,
//   Button,
//   Tooltip,
//   IconButton,
//   Card,
// } from "@mui/material";
// import { useDispatch } from "react-redux";
// import { Link } from "react-router-dom";
// import styles from "../assets/styles/PageTitle.module.css";
// import user1 from "../assets/images/user2.png";
// import pic from "../assets/images/images-removebg-preview.png";
// import { useSelector } from "react-redux";
// import user2 from "../assets/images/user5.png";
// import user3 from "../assets/images/user7.png";
// import { routesName } from "../routes/routesName";
// import { helperText } from "../HelperText/Text";
// import { GetpublicpageAction } from "../redux/Actions/GetActions/getpublicpageAction";
// import { getTeacherData } from "../loginData/loginData";
// const TeacherCard = () => {
//   const { loginData } = useSelector((state) => state?.LOGINREDUCER);

//   const dispatch = useDispatch();
//   useEffect(() => {
//     console.log("Dispatching GetpublicpageAction...");
//     dispatch(
//       GetpublicpageAction(
//         (response) => {
//           console.log("Public Page retrieval successful:", response.message);
//         },
//         (error) => {
//           console.error("Public Page retrieval failed:", error.message);
//           alert(error.payload);
//         }
//       )
//     );
//   }, []);

//   const TeacherData = getTeacherData(loginData);

//   const { publicpage } = useSelector((state) => state?.PUBPAGE);
//   console.log("publicPageContent", publicpage.payload);

//   const groupedData = publicpage?.payload.reduce((acc, obj) => {
//     const plannedCourseId = obj.PlannedCourseId;
//     if (!acc[plannedCourseId]) {
//       acc[plannedCourseId] = []; // Initialize an empty array for the PlannedCourseId if it doesn't exist
//     }
//     acc[plannedCourseId].push(obj); // Push the object into the corresponding PlannedCourseId array
//     return acc;
//   }, {});

//   console.log("groupedData", groupedData); // Moved the console.log statement here
//   Object.entries(groupedData).map((classItem) => (console.log(classItem[0])));
//   return (
//     <>
//     CV, K
//       <Card
//         sx={{
//           boxShadow: "none",
//           borderRadius: "10px",
//           p: "5px",
//           mb: "15px",
//         }}
//       >
//         <div
//           className={styles.pageTitle}
//           style={{ padding: "4px", textAlign: "center" }}
//         >
//           <h1>{helperText.publicPage}</h1>
//           <ul>
//             <li>
//               <span>{helperText.publicPage}</span>
//             </li>
//           </ul>
//         </div>
//         <Grid
//           container
//           justifyContent="left"
//           rowSpacing={1}
//           columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2 }}
//         >
//           {Object.entries(groupedData).map(([courseId, course]) => (
//             <Grid item xs={12} md={6} lg={6} xl={3} key={courseId[0]}>
//             <Box
//                 sx={{
//                   boxShadow: "none",
//                   borderRadius: "10px",
//                   mb: "15px",
//                 }}
//               >
//                 <Box
//                   sx={{
//                     display: "flex",
//                     justifyContent: "space-between",
//                     alignItems: "center",
//                     p: "20px",
//                     // boxShadow: "0px 4px 20px rgba(47, 143, 232, 0.07)",
//                     boxShadow: "0px 4px 20px rgba(128, 128, 128, 0.5)",
//                   }}
//                   className="ClassroomCard-card-dark"
//                 >
//                   <Box
//                     sx={{
//                       display: "flex",
//                       justifyContent: "space-between",
//                       alignItems: "center",
//                     }}
//                   >
//                     <Avatar
//                       src={pic}
//                       alt="Member"
//                       width="55px"
//                       height="55px"
//                       className="borRadius100"
//                       style={{ border: "2px solid #757FEF" }}
//                     />
//                     <Box className="ml-1">
//                       <Typography
//                         as="h3"
//                         sx={{
//                           fontSize: 15,
//                           fontWeight: "bold",
//                         }}
//                       >
//                         {course[0].CourseName}
//                       </Typography>
//                       <Typography
//                         sx={{
//                           fontSize: 13,
//                         }}
//                       >
//                         {course[0].CourseName}
//                       </Typography>
//                     </Box>
//                   </Box>
//                 </Box>

//                 <Box
//                   sx={{
//                     p: "20px",
//                     // border: "0.0625rem solid rgb(218, 220, 224)"
//                     // boxShadow: "0px 4px 20px rgba(47, 143, 232, 0.07)",
//                     boxShadow: "0px 4px 20px rgba(128, 128, 128, 0.5)",
//                   }}
//                 >
//                   <Box mb={2}>
//                     <Typography
//                       as="h3"
//                       sx={{
//                         fontSize: 15,
//                         fontWeight: 500,
//                         mb: "5px",
//                       }}
//                     >
//                       BS Computer Science
//                     </Typography>
//                     <Typography
//                       sx={{
//                         fontSize: 13,
//                       }}
//                     >
//                       {course[0].CourseName}
//                     </Typography>
//                   </Box>

//                   <Box mb={2}>
//                     <Box
//                       sx={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                         alignItems: "end",
//                       }}
//                     >
//                       <Box>
//                         <Typography
//                           as="h3"
//                           sx={{
//                             fontSize: 14,
//                             fontWeight: 500,
//                             mb: "5px",
//                           }}
//                         >
//                           Student Enrolled
//                         </Typography>
//                         <Typography
//                           sx={{
//                             fontSize: 13,
//                           }}
//                         >
//                           Total: {course[0].EnrollmentCount}
//                         </Typography>
//                       </Box>

//                       <Box>
//                         <Typography
//                           sx={{
//                             fontSize: 13,
//                           }}
//                         ></Typography>
//                       </Box>
//                     </Box>
//                   </Box>

//                   <Box
//                     sx={{
//                       display: "flex",
//                       justifyContent: "space-between",
//                       alignItems: "center",
//                     }}
//                   >
//                     <Box>
//                       <AvatarGroup max={4}>
//                         <Avatar src={user1} alt="User 1" />
//                         <Avatar src={user2} alt="User 2" />
//                         <Avatar src={user3} alt="User 3" />
//                       </AvatarGroup>
//                     </Box>

//                     <Box>
//                       <Link
//                         to={`${routesName.classroomHome}/${course[0].CourseName}/${course[0].CourseId}`}
//                         key={course[0].CourseId}
//                         state={course[0]}
//                         className="text-decoration-none"
//                       >
//                         <Button
//                           variant="contained"
//                           color="primary"
//                           sx={{
//                             borderRadius: "4px",
//                             textTransform: "capitalize",
//                             color: "#fff !important",
//                           }}
//                         >
//                           view details
//                         </Button>
//                       </Link>
//                     </Box>
//                   </Box>
//                 </Box>
//               </Box>
//       </Grid>
//           ))}
//           </Grid>

//       </Card>
//     </>
//   );
// };

// export default TeacherCard;



// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   Typography,
//   Grid,
//   Avatar,
//   AvatarGroup,
//   Button,
//   Card,
// } from "@mui/material";
// import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
// import { GetpublicpageAction } from "../redux/Actions/GetActions/getpublicpageAction";
// import { routesName } from "../routes/routesName";
// import { helperText } from "../HelperText/Text";
// import user1 from "../assets/images/user2.png";
// import user2 from "../assets/images/user5.png";
// import user3 from "../assets/images/user7.png";
// import pic from "../assets/images/images-removebg-preview.png";
// import styles from "../assets/styles/PageTitle.module.css";

// const TeacherCard = () => {
//   const { loginData } = useSelector((state) => state?.LOGINREDUCER);
//   const { publicpage } = useSelector((state) => state?.PUBPAGE);
//   const dispatch = useDispatch();

//   useEffect(() => {
//     console.log("Dispatching GetpublicpageAction...");
//     dispatch(GetpublicpageAction(
//       (response) => {
//         console.log("Public Page retrieval successful:", response.message);
//       },
//       (error) => {
//         console.error("Public Page retrieval failed:", error.message);
//         alert(error.payload);
//       }
//     ));
//   }, [dispatch]);

//   const groupedData = publicpage?.payload ? publicpage.payload.reduce((acc, obj) => {
//     const plannedCourseId = obj.PlannedCourseId;
//     if (!acc[plannedCourseId]) {
//       acc[plannedCourseId] = []; // Initialize an empty array for the PlannedCourseId if it doesn't exist
//     }
//     acc[plannedCourseId].push(obj); // Push the object into the corresponding PlannedCourseId array
//     return acc;
//   }, {}) : {};

//   console.log("groupedData", groupedData);

//   return (
//     <>
//       <Card
//         sx={{
//           boxShadow: "none",
//           borderRadius: "10px",
//           p: "5px",
//           mb: "15px",
//         }}
//       >
//         <div
//           className={styles.pageTitle}
//           style={{ padding: "4px", textAlign: "center" }}
//         >
//           <h1>{helperText.publicPage}</h1>
//           <ul>
//             <li>
//               <span>{helperText.publicPage}</span>
//             </li>
//           </ul>
//         </div>
//         <Grid
//           container
//           justifyContent="left"
//           rowSpacing={1}
//           columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2 }}
//         >
//           {Object.entries(groupedData).map(([courseId, course]) => (
//             <Grid item xs={12} md={6} lg={6} xl={3} key={courseId}>
//               <Box
//                 sx={{
//                   boxShadow: "none",
//                   borderRadius: "10px",
//                   mb: "15px",
//                 }}
//               >
//                 <Box
//                   sx={{
//                     display: "flex",
//                     justifyContent: "space-between",
//                     alignItems: "center",
//                     p: "20px",
//                     boxShadow: "0px 4px 20px rgba(128, 128, 128, 0.5)",
//                   }}
//                   className="ClassroomCard-card-dark"
//                 >
//                   <Box
//                     sx={{
//                       display: "flex",
//                       justifyContent: "space-between",
//                       alignItems: "center",
//                     }}
//                   >
//                     <Avatar
//                       src={pic}
//                       alt="Member"
//                       width="55px"
//                       height="55px"
//                       className="borRadius100"
//                       style={{ border: "2px solid #757FEF" }}
//                     />
//                     <Box className="ml-1">
//                       <Typography
//                         as="h3"
//                         sx={{
//                           fontSize: 15,
//                           fontWeight: "bold",
//                         }}
//                       >
//                         {course[0].CourseName}
//                       </Typography>
//                       <Typography
//                         sx={{
//                           fontSize: 13,
//                         }}
//                       >
//                         {course[0].CourseName}
//                       </Typography>
//                     </Box>
//                   </Box>
//                 </Box>

//                 <Box
//                   sx={{
//                     p: "20px",
//                     boxShadow: "0px 4px 20px rgba(128, 128, 128, 0.5)",
//                   }}
//                 >
//                   <Box mb={2}>
//                     <Typography
//                       as="h3"
//                       sx={{
//                         fontSize: 15,
//                         fontWeight: 500,
//                         mb: "5px",
//                       }}
//                     >
//                       BS Computer Science
//                     </Typography>
//                     <Typography
//                       sx={{
//                         fontSize: 13,
//                       }}
//                     >
//                       {course[0].CourseName}
//                     </Typography>
//                   </Box>

//                   <Box mb={2}>
//                     <Box
//                       sx={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                         alignItems: "end",
//                       }}
//                     >
//                       <Box>
//                         <Typography
//                           as="h3"
//                           sx={{
//                             fontSize: 14,
//                             fontWeight: 500,
//                             mb: "5px",
//                           }}
//                         >
//                           Student Enrolled
//                         </Typography>
//                         <Typography
//                           sx={{
//                             fontSize: 13,
//                           }}
//                         >
//                           Total: {course[0].EnrollmentCount}
//                         </Typography>
//                       </Box>

//                       <Box>
//                         <Typography
//                           sx={{
//                             fontSize: 13,
//                           }}
//                         ></Typography>
//                       </Box>
//                     </Box>
//                   </Box>

//                   <Box
//                     sx={{
//                       display: "flex",
//                       justifyContent: "space-between",
//                       alignItems: "center",
//                     }}
//                   >
//                     <Box>
//                       <AvatarGroup max={4}>
//                         <Avatar src={user1} alt="User 1" />
//                         <Avatar src={user2} alt="User 2" />
//                         <Avatar src={user3} alt="User 3" />
//                       </AvatarGroup>
//                     </Box>

//                     <Box>
//                       <Link
//                         to={`${routesName.classroomHome}/${course[0].CourseName}/${course[0].CourseId}`}
//                         key={course[0].CourseId}
//                         state={course[0]}
//                         className="text-decoration-none"
//                       >
//                         <Button
//                           variant="contained"
//                           color="primary"
//                           sx={{
//                             borderRadius: "4px",
//                             textTransform: "capitalize",
//                             color: "#fff !important",
//                           }}
//                         >
//                           view details
//                         </Button>
//                       </Link>
//                     </Box>
//                   </Box>
//                 </Box>
//               </Box>
//             </Grid>
//           ))}
//         </Grid>
//       </Card>
//     </>
//   );
// };

// export default TeacherCard;







// import React from 'react';
// import { Box, Button } from "@mui/material";

// export default function PublicPage() {
//   return (
//     <>
//       <Box sx={{ display: 'flex', justifyContent: 'space-evenly', borderBottom: "1px solid rgba(229,231,242,.85) " }} p={1}>
//         <div>
//           <span
//             style={{
//               lineHeight: '45px',
//               fontSize: '12.4px',
//               letterSpacing: '0',
//             }}
//           >
//             ubs@itu.edu.pk
//           </span>
//         </div>
//         <div>
//           <button
//             style={{
//               lineHeight: '45px',
//               fontSize: '15.4px',
//               letterSpacing: '0',
//             }}
//           >
//             Login
//           </button>
//         </div>
//       </Box>

//       <Box>
//         <section className="text-gray-600 body-font">
//           <div className="container px-5 py-24 mx-auto flex flex-wrap">
//             <div className="flex flex-wrap -mx-4 mt-auto mb-auto lg:w-1/2 sm:w-2/3 content-start sm:pr-10">
//               <div className="w-full sm:p-4 px-4 mb-6">
//                 <h1 className="title-font font-medium text-xl mb-2 text-gray-900" style={{ fontSize: '34px' }} >Usama Bin Shakeel</h1>
//                 <div className="leading-relaxed">It has been more than a decade for me in the business of development for mobile application,
//                   web and software. My passion has always been to code in an optimised manner and solve complex problems irrespective whether it was while studying or now while working on industrial challenges.</div>
//               </div>
//               {/* <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
//         <h2 className="title-font font-medium text-3xl text-gray-900">2.7K</h2>
//         <p className="leading-relaxed">Users</p>
//       </div>
//       <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
//         <h2 className="title-font font-medium text-3xl text-gray-900">1.8K</h2>
//         <p className="leading-relaxed">Subscribes</p>
//       </div>
//       <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
//         <h2 className="title-font font-medium text-3xl text-gray-900">35</h2>
//         <p className="leading-relaxed">Downloads</p>
//       </div>
//       <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
//         <h2 className="title-font font-medium text-3xl text-gray-900">4</h2>
//         <p className="leading-relaxed">Products</p>
//       </div> */}
//             </div>
//             <div className="lg:w-1/2 sm:w-1/3 w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
//               <img className="object-cover object-center w-full h-full" src="https://dummyimage.com/600x300" alt="stats" />
//             </div>
//           </div>
//         </section>
//       </Box>


//       <Box>
//         <section className="text-gray-600 body-font">
//           <div className="container px-5 py-24 mx-auto">
//             <h1 className="sm:text-3xl text-2xl font-medium title-font text-center text-gray-900 mb-20">
//               EDUCATION
//             </h1>
//             <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
//               <div className="p-4 md:w-1/3 flex">
//                 <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4 flex-shrink-0">
//                   <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-6 h-6" viewBox="0 0 24 24">
//                     <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
//                   </svg>
//                 </div>
//                 <div className="flex-grow pl-6">
//                   <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
//                     MASTERS IN COMPUTER SCIENCE</h2>
//                   <p className="leading-relaxed text-base">Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>
//                   <a className="mt-3 text-indigo-500 inline-flex items-center">Learn More
//                     <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
//                       <path d="M5 12h14M12 5l7 7-7 7"></path>
//                     </svg>
//                   </a>
//                 </div>
//               </div>
//               <div className="p-4 md:w-1/3 flex">
//                 <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4 flex-shrink-0">
//                   <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-6 h-6" viewBox="0 0 24 24">
//                     <circle cx="6" cy="6" r="3"></circle>
//                     <circle cx="6" cy="18" r="3"></circle>
//                     <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
//                   </svg>
//                 </div>
//                 <div className="flex-grow pl-6">
//                   <h2 className="text-gray-900 text-lg title-font font-medium mb-2">BACHELORS IN COMPUTER ENGINEERING</h2>
//                   <p className="leading-relaxed text-base">Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>
//                   <a className="mt-3 text-indigo-500 inline-flex items-center">Learn More
//                     <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
//                       <path d="M5 12h14M12 5l7 7-7 7"></path>
//                     </svg>
//                   </a>
//                 </div>
//               </div>
//               <div className="p-4 md:w-1/3 flex">
//                 <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4 flex-shrink-0">
//                   <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-6 h-6" viewBox="0 0 24 24">
//                     <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
//                     <circle cx="12" cy="7" r="4"></circle>
//                   </svg>
//                 </div>
//                 <div className="flex-grow pl-6">
//                   <h2 className="text-gray-900 text-lg title-font font-medium mb-2">GCE ADVANCED LEVEL</h2>
//                   <p className="leading-relaxed text-base">Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>
//                   <a className="mt-3 text-indigo-500 inline-flex items-center">Learn More
//                     <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
//                       <path d="M5 12h14M12 5l7 7-7 7"></path>
//                     </svg>
//                   </a>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//       </Box>
//     </>
//   );
// }

import React,{useState} from 'react';
import { Box, Button,Menu,MenuItem  } from "@mui/material";
import ListItemIcon from '@mui/material/ListItemIcon';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
export default function PublicPage() {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option) => {
    console.log(`Selected option: ${option}`);
    handleClose();
  };
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-around', borderBottom: "1px solid rgba(229,231,242,.85) " }} p={1}>
        <div>
          <span
            style={{
              lineHeight: '45px',
              fontSize: '12.4px',
              letterSpacing: '0',
            }}
          >
            ubs@itu.edu.pk
          </span>
        </div>
        <div>
          <Button style={{
            lineHeight: '45px',
            fontSize: '12.4px',
            letterSpacing: '0',
          }}>Login</Button>
        </div>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-around', }} p={1}>

        <Button onClick={() => scrollToSection('about')}>About</Button>
        <Button onClick={() => scrollToSection('education')}>Education</Button>
        {/* <Button onClick={() => scrollToSection('public--content')}>Public Content</Button> */}
        <Button
        endIcon={<ArrowForwardIosIcon />}
        aria-controls="menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        ITU
      </Button>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleMenuItemClick('Option 1')}>Option 1</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('Option 2')}>Option 2</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('Option 3')}>Option 3</MenuItem>
      </Menu>
      </Box>

      <section id="about" className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto flex flex-wrap">
          <div className="flex flex-wrap -mx-4 mt-auto mb-auto lg:w-1/2 sm:w-2/3 content-start sm:pr-10">
            <div className="w-full sm:p-4 px-4 mb-6">
              <h1 className="title-font font-medium text-xl mb-2 text-gray-900" style={{ fontSize: '34px' }}>Usama Bin Shakeel</h1>
              <div className="leading-relaxed">It has been more than a decade for me in the business of development for mobile application,
                web and software. My passion has always been to code in an optimised manner and solve complex problems irrespective whether it was while studying or now while working on industrial challenges.</div>
            </div>
          </div>
          <div className="lg:w-1/2 sm:w-1/3 w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
            <img className="object-cover object-center w-full h-full" src="https://dummyimage.com/600x300" alt="stats" />
          </div>
        </div>
      </section>

      <section id="education" className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto">
          <h1 className="sm:text-3xl text-2xl font-medium title-font text-center text-gray-900 mb-20">
            EDUCATION
          </h1>
          <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
            <div className="p-4 md:w-1/3 flex">
              <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4 flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-6 h-6" viewBox="0 0 24 24">
                  <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                </svg>
              </div>
              <div className="flex-grow pl-6">
                <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                  MASTERS IN COMPUTER SCIENCE</h2>
                <p className="leading-relaxed text-base">Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>
                <a className="mt-3 text-indigo-500 inline-flex items-center">Learn More
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </a>
              </div>
            </div>
            <div className="p-4 md:w-1/3 flex">
              <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4 flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-6 h-6" viewBox="0 0 24 24">
                  <circle cx="6" cy="6" r="3"></circle>
                  <circle cx="6" cy="18" r="3"></circle>
                  <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                </svg>
              </div>
              <div className="flex-grow pl-6">
                <h2 className="text-gray-900 text-lg title-font font-medium mb-2">BACHELORS IN COMPUTER ENGINEERING</h2>
                <p className="leading-relaxed text-base">Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>
                <a className="mt-3 text-indigo-500 inline-flex items-center">Learn More
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </a>
              </div>
            </div>
            <div className="p-4 md:w-1/3 flex">
              <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4 flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-6 h-6" viewBox="0 0 24 24">
                  <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
              </div>
              <div className="flex-grow pl-6">
                <h2 className="text-gray-900 text-lg title-font font-medium mb-2">GCE ADVANCED LEVEL</h2>
                <p className="leading-relaxed text-base">Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>
                <a className="mt-3 text-indigo-500 inline-flex items-center">Learn More
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>


    </>
  );
}
