import { UPDATEBOOKS ,ADMIN_UPDATE_BOOKS } from "../ActionType/Types";

import Constants from "../../Constant"

export function UpdatebookAction(token,BookId ,CourseId,BookIBN,BookName ,onSuccess, onFailure){
    return{
        type:ADMIN_UPDATE_BOOKS,
        payload:{
            apiUrl: `${Constants.updatebook}/${BookId}`,
            header:"application/json",
            metaData:true,
            requestType:'PUT',
            reduxActionType:UPDATEBOOKS,
            onSuccess:onSuccess,
            onFailure:onFailure,
            body:{
                BookIBN:BookIBN,
               token:token,
               idObject:{
                CourseId:CourseId,
               },
               namesObject:{
                BookName:BookName,
               },
            }
        }
    }
}