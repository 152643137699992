import { DELETESTUENTSUBMISSION ,ADMIN_DELETE_STUDENT_SUBMISSION } from "../ActionType/Types";
import Constants from "../../Constant"
export function DeletestudentsubmissionAction(token,StudentSubmissionId,EnrollementId,SubComponentId,questionids,onSuccess ,onFailure){
    console.log("DeletestudentsubmissionAction",token,StudentSubmissionId,EnrollementId,SubComponentId,questionids)
    return{ 
        type:ADMIN_DELETE_STUDENT_SUBMISSION,
        payload:{
            apiUrl: Constants.deletestudentsubmission,
            metaData:true,
            header:"application/json",
            requestType:'POST',
            reduxActionType:DELETESTUENTSUBMISSION,
            onSuccess:onSuccess,
            onFailure:onFailure,
            body:{
                token:token,
                StudentSubmissionId:StudentSubmissionId,
                EnrollmentId:EnrollementId,
                SubComponentId:SubComponentId,
                questionIds:questionids
            } 
        }
    }
}