import { GETCLASSCOMPONENT,ADMIN_GET_CLASSCOMPONENT } from "../ActionType/Types";
import Constants from "../../Constant"

export function GetclasscomponentAction(token ,CourseId,isPublic=false,onSuccess ,onFailure){
    const isPublicUrl=isPublic?`?isPublic=1`:"";
    const apiUrl=`${Constants.getclasscomponent}/${CourseId}${isPublicUrl}`;
    return{
        type:ADMIN_GET_CLASSCOMPONENT,
        payload:{
            metaData: true,
            header:"/application/json",
            apiUrl:apiUrl,
            requestType:'GET',
            reduxActionType: GETCLASSCOMPONENT  ,
            onSuccess:onSuccess,
            onFailure:onFailure,
            token:token,
        }
    }
}