import { GETSUBCOMPONENT, ADMIN_GET_SUBCOMPONENT } from "../ActionType/Types";
import Constants from "../../Constant"


export function GetsubcomponentAction(token,componentID,draft=false,onSuccess ,onFailure){
    const draftApiUrl=draft?"?draft=1":""
    const apiUrl=`${Constants.getsubcomponent}/${componentID}${draftApiUrl}`;
    return{
        type:ADMIN_GET_SUBCOMPONENT,
        payload:{
            apiUrl: apiUrl,
            metaData:true,
            header:"application/json",
            requestType:'GET',
            reduxActionType:GETSUBCOMPONENT,
            onSuccess:onSuccess,
            onFailure:onFailure,
            body:{
                token:token,
            }
        }
    }
}