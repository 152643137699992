import { GETBOOKS ,ADMIN_GET_BOOKS } from "../ActionType/Types";
import Constants from "../../Constant"
export function GetbookAction(token ,onSuccess ,onFailure){
    return{
        type:ADMIN_GET_BOOKS,
        payload:{
            metaData: true,
            header:"/application/json",
            apiUrl:Constants.getbook,
            requestType:'GET',
            reduxActionType: GETBOOKS  ,
            onSuccess:onSuccess,
            onFailure:onFailure,
            token:token,
        }
    }
}