
  import React, { useState, useEffect } from "react";
  import {
    Typography,
    CircularProgress,
    Card,
    MenuItem,
    Radio,
    FormControlLabel,
    RadioGroup,
    FormControl,
    InputAdornment,
    Box,
  } from "@mui/material";
  import loginImg from "../assets/images/flc_design20240310222273.png"
  import { EmailOutlined as EmailOutlinedIcon, Label } from "@mui/icons-material";
  import { useDispatch, useSelector } from "react-redux";
  import { LoginAction } from "../redux/Actions/LoginAction";
  import { helperText } from "../HelperText/Text";
  import { useNavigate } from "react-router-dom";
  import { routesName } from "../routes/routesName";
  import { getRoleData } from "../loginData/loginData";
  import {
    isValidEmail,
  } from "../validation/validtionFunctions";
  import "./auth.css"

  export default function LoginPage() {
    console.log("Windows.location.href:::",window.location.href)
    const { loginData } = useSelector((state) => state.LOGINREDUCER);
    const navigate = useNavigate();
    const roleData = getRoleData(loginData);
    const [getuserRoll, setgetuserRoll] = useState("");
    useEffect(() => {
      const { RoleId, RoleName, UserroleId } = roleData || {};
    }, [loginData]);
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [deviceName, setDeviceName] = useState("");
    const handlePasswordToggle = () => {
      setShowPassword((prevPassword) => !prevPassword);
    };
    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        handleLoginAction();
      }
    };
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [loginError, setLoginError] = useState(null);
    const handleLoginAction = () => {
      if (!email) {
        setLoginError(helperText.emptyfeilderror);
        setIsLoggingIn(false);
        return;
      }
      if (!isValidEmail(email)) {
        setLoginError(helperText.loginvalidationEmail);
        setIsLoggingIn(false);
        return;
      }
      if (!getuserRoll) {
        setLoginError(helperText.rolldata);
        setIsLoggingIn(false);
        return;
      }
  
      setIsLoggingIn(true);
      const userAgent = window.navigator.userAgent;
      setDeviceName(userAgent);
      dispatch(
        LoginAction(
          email,
          getuserRoll,
          (response) => {
              navigate(routesName.otp, {
                state: { email: email, userRole: getuserRoll },
              });
          },
          (error) => {
            setLoginError(error.message.payload.email??error.message.payload.role??""); 
            setIsLoggingIn(false);
          }
        )
      );
        };  
    return (

      <div className="login-container ">
        
         <Box className=" flex-col      shadow-md rounded-[4px]  ">
        <div className="flex items-center justify-center h-screen for-dark-bottom-border ">
          <Card className="min-w-fit flex-col   px-6 py-14 shadow-md rounded-[4px] box-shadow-animation">
           
            <div className="flex justify-center">
            <img  src={loginImg} width={'130px'} className="image-animation"/>

            </div>
            <div className="flex flex-col text-sm rounded-md search-form mt-2">
            <h1 className="title-font text-lg font-medium text-gray-900 ">Email</h1>
              <input
                className="mb-5 rounded-[4px] border p-3 hover:outline-none focus:outline-none "
                type="email"
                placeholder="Enter Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{background:"#F5F7FA",color: "black",}}
              />
            </div>
            <div className="flex justify-center pt-4">
              {isLoggingIn ? (
                <CircularProgress size={24} />
              ) : (
                <button
                  onKeyDown={handleKeyPress}
                  onClick={handleLoginAction}
                  className="mt-5 w-full border p-2 bg-gradient-to-r from-gray-800 bg-gray-500 text-white rounded-[4px] hover:bg-slate-400 scale-105 duration-300"
                  type="submit"
                >
                  Log in
                </button>
              )}
            </div>
            <div className="pt-4">
            </div>
            <div className="flex items-center mb-5">
              <p className="m-0 title-font text-sm font-medium text-black " style={{fontSize:"15px"}}>Please select your Role</p>
              <div className="flex flex-grow ml-4">
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="roll"
                    name="roll"
                    value={getuserRoll}
                    onChange={(e) => setgetuserRoll(e.target.value)}
                    row
                  >
                    <FormControlLabel
                      value="Student"
                      control={<Radio />}
                      label="Student"
                      sx={{color:"black",fontWeight:"400"}}

                    />
                    <FormControlLabel
                      value="Teacher"
                      control={<Radio />}
                      label="Teacher"
                      sx={{color:"black",fontWeight:"400"}}
                    />
                    <FormControlLabel value="TA" control={<Radio />}    sx={{color:"black",fontWeight:"400"}} label="TA" />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <div className="mt-5 flex justify-between text-sm text-gray-600">
              {/* <Link to={routesName.forgetpassword}>Forgot password?</Link> */}
              {loginError && (
                <Typography
                  variant="body2"
                  color="error"
                  className="text-center mt-4"
                >
                  {loginError}
                </Typography>
              )}
            </div>
          </Card>
        </div>
        </Box>
        </div>
    );
  }
