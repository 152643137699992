import { ADMINGETSTUDENTATTENDANCE ,ADMIN_GET_STUDENT_ATTENDANCE } from "../ActionType/Types";
import Constants from "../../Constant"
export function GetStudentattendanceAction(token,EnrollementId,CourseId ,onSuccess ,onFailure,courseFlag=0){
    return{
        type:ADMIN_GET_STUDENT_ATTENDANCE,
        payload:{
            metaData: true,
            header:"/application/json",
            apiUrl: `${Constants.studentattendance}/${EnrollementId}/${CourseId}/${courseFlag}`,
            requestType:'GET',
            reduxActionType: ADMINGETSTUDENTATTENDANCE  ,
            onSuccess:onSuccess,
            onFailure:onFailure,
            token:token,
            // body:{
            //     CourseId:CourseId,
            // }
        }
    }
}