import { GETSTUDENTBYCOURSE,GET_STUDENT_BY_COURSE } from "../ActionType/Types";
import Constants from "../../Constant"

export function GetStudentByCourseAction(token,Id ,onSuccess ,onFailure){
    return{
        type:GET_STUDENT_BY_COURSE,
        payload:{
            metaData: true,
            header:"/application/json",
            apiUrl:`${Constants.studentbycourseid}/${Id}`,
            requestType:'GET',
            reduxActionType: GETSTUDENTBYCOURSE  ,
            onSuccess:onSuccess,
            onFailure:onFailure,
            token:token,
        }
    }
}