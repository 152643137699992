// GETSTUDENTENROLLEMENTFORCOURSE
import { GETSTUDENTENROLLEMENTFORCOURSE ,LOGOUT } from "../../Actions/ActionType/Types";

const initialState={
    getcourseenrollementData:[]
}
export default function GetstudentenrollmentforcourseReducer(state =initialState,action){
    switch (action.type){
        case GETSTUDENTENROLLEMENTFORCOURSE:
            return{
                ...state,
                getcourseenrollementData: action.payload,
            };
            case LOGOUT:return initialState;
        default:return state;
    }
}