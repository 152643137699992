
export const getUserData = (loginData) => {
  return loginData?.payload?.userData?.[0] || null;
};

export const getRoleData = (loginData) => {
  const roleData = loginData?.payload?.roleData?.[0] || null;
  return roleData
    ? {
        RoleId: roleData?.RoleId,
        RoleName: roleData?.RoleName,
        UserroleId: roleData?.UserroleId,
      }
    : null;
};

export const getStudentData = (loginData) => {
  return loginData?.payload?.studentData || [];
};

export const gettaData = (loginData) => {
  return loginData?.payload?.taData || null;
};

export const getAccessToken = (loginData) => {
  return loginData?.payload?.accessToken || null;
};

export const getTeacherData=(loginData)=>{
  return loginData?.payload?.teacherData || null;
}

export const getStudentUserId = (loginData) => {
  const studentData = loginData?.payload?.studentUserId?.[0];
  return studentData
    ? {
      studentUserId: studentData?.StudentUserId,
      }
    : null;
};