
  export const mainBox={
    maxWidth: "510px",
    ml: "auto",
    mr: "auto",
    padding: "50px 0 100px",
  }
  
  
  export const formBox={
    background: "#fff",
    padding: "30px 20px",
    borderRadius: "10px",
    mb: "20px",
  }
  export const formBtn={
    mt: 1,
    textTransform: "capitalize",
    borderRadius: "8px",
    fontWeight: "500",
    fontSize: "16px",
    padding: "12px 10px",
    color: "#fff !important"
  }

  export const TypographySx={
    fontWeight: "500",
    fontSize: "14px",
    mb: "10px",
    display: "block",
  }