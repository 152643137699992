import { POSTATTACHMENTS } from "../../Actions/ActionType/Types";

const initialState={
    addattachmenData:[]
}
export default function PostattachmentsReducer(state =initialState,action){
    switch (action.type){
        case POSTATTACHMENTS:
            return{
                ...state,
                addattachmenData: action.payload,
            };
        default:return state;
    }
}