import { ADMIN_POST_CHATS } from "../../Actions/ActionType/Types";


import {takeEvery} from "redux-saga/effects";
import fetchData from "../sagaHelper";

export  function * PostchatSaga(){

    yield takeEvery(ADMIN_POST_CHATS,fetchData);
}
